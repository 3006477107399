import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setDepartmentListDetails, setGeneralDetails} from "../../../actions/setactiondetails";
import { serverLink } from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/course.jpg";
import CourseContent from "./section/course-cotent";
import DataLoader from "../../common/dataLoader/dataLoader";
import CourseSide from "./section/course-side";
import PageName from "../../common/pagename/pagename";

const Courses = (props)=>{
    const [loading, setLoading] = useState(props.courseList.length < 1 );
    const [search, setSearch] = useState("");
    const [courseList, setCourseList] = useState(
        props.courseList.filter((e) =>
            e.DEPARTMENT.toLowerCase().includes(search.toLowerCase())
        )
    );
    const [courses, setCourses] = useState(props.courseList);

    const getData = async () => {
        await axios
            .get(`${serverLink}general/department/list`)
            .then((result) => {
                const data = result.data;
                setLoading(false);
                setCourseList(data);
                props.setOnCourseDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);
    useEffect(() => {
        setCourseList(
            props.courseList.filter((e) =>
                e.DEPARTMENT.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search]);

    const onCourseChange = (e) => {
        const selectedCourse = e.target.value;
        setSearch(selectedCourse);
    };

    const handleOnFacultyClick = (e) => {
        setCourseList(props.courseList.filter((item) => item.COL_ID === e));
    };

    return loading ? (
        <section className="space-ptb position-relative">
            <div className="container" style={{height: '400px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
        </section>
    ) : (
        <>
            <PageName page={"Courses"} />
            <Banner
                Image={bg3}
                Title={"Our Courses"}
                breadcrumb={["Admission", "Our Courses"]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="" style={{marginLeft:"20px", marginRight:"20px"}}>
                    <div className="row">
                        <CourseContent courseList={courseList} onCourseChange={onCourseChange} />
                        <CourseSide
                            courseList={courses}
                            onCourseChange={onCourseChange}
                            handleOnFacultyClick={handleOnFacultyClick}
                        />


                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state.DepartmentListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnCourseDetails: (p) => {
            dispatch(setDepartmentListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Courses);
  