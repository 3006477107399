import React, {useState } from "react";
import Slider from "./section/slider";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import DataLoader from "../common/dataLoader/dataLoader";
import WhyChooseUs from "./section/why-us-section";
import FindCourse from "./section/find-course-section";
import CounterSection from "./section/counter-section";
import LatestNewsSection from "./section/latest-news-section";
import LearningCentersSection from "./section/learning-section";
import GallerySection from "./section/gallery-section";
import StatementSection from "./section/statement-section";



function Homepage(props) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading ? (
          <section className="space-ptb position-relative">
            <div className="container" style={{height: '400px'}}>
              <div className="page-header d-print-none">
                <DataLoader/>
              </div>
            </div>
          </section>
      ) : (
        <>
          <PageName page={"Home"} />
          <Slider sliderList={props.sliderList} />
          <FindCourse departmentList={props.departmentList} />
          <CounterSection collegeList={props.collegeList} departmentList={props.departmentList} />
          <WhyChooseUs/>
          <LatestNewsSection newsList={props.newsList}/>
          <LearningCentersSection/>
          <GallerySection  galleryList={props.galleryList} />
          <StatementSection/>
    </>
      )}
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    collegeList: state.FacultyListDetails,
    courseList: state.CourseListDetails,
    departmentList: state.DepartmentListDetails,
    staffList: state.StaffListDetails,
    newsList: state.NewsDetails,
    sliderList: state.SliderDetails,
    schoolsList: state.SchoolsDetails,
    programmeList: state.ProgrammeDetails,
    galleryList: state.GalleryDetails,
  };
};
export default connect(mapStateToProps, null)(Homepage);
