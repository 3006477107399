import React, {useState} from "react";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {Link} from "react-router-dom";
const CryptoJS = require("crypto-js");


function ForgottenPassword(props) {
    const [IsLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        EmailAddress: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.EmailAddress.toString().trim() === "") {
            showAlert("Empty Field", "Please enter your email address or phone number", "error");
            return false;
        }
        const sendData = {
            ...formData
        }

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}login/student/forgotten-password`, sendData)
            .then(result => {
                const response = result.data.message;
                console.log(response)
                if (response === 'invalid') {
                    showAlert("USER NOT FOUND", "Your email address or phone number doesn't exist on our platform. Kindly check your email or phone number and try again", "error");
                } else if (response === 'success') {
                    // toast.success("Credential reset was submitted successfully. An email was sent to your registered email address.");
                    toast.success("Credential reset was submitted successfully. An SMS was sent to your registered phone number.");
                    document.getElementById("EmailAddress").value = "";
                    setFormData({
                        EmailAddress: "",
                    })
                }else{
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    return (
        <>
            <PageName page={"Student Login"} />
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                                <h2>Recover Password</h2>
                                <p>Reset Your Forgotten Password</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2">
                            <form className="login bg-white shadow p-5">
                                <div className="form-group">
                                    <label>Email address / Phone Number</label>
                                    <input type="text" className="form-control" id="EmailAddress" name="EmailAddress"   onChange={handleChange} placeholder="Enter your email address or phone number" />
                                    <span className="focus-border"/>
                                </div>
                                {
                                    IsLoading ?
                                        <button type="submit" className="btn btn-dark mt-4 w-100" disabled={IsLoading}>Please Wait...</button>
                                        :
                                        <button type="submit" className="btn btn-dark mt-4 w-100"  onClick={handleSubmit}>SUBMIT</button>
                                }


                            </form>

                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(ForgottenPassword);