import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    setGeneralDetails,
    setNewsDetails,
} from "../../../../actions/setactiondetails";
import axios from "axios";
import {
    formatDateAndTime,
    projectFacebook,
    projectInstagram, projectLinkedIn,
    projectTwitter,
    serverLink
} from "../../../../resources/url";
import Banner from "../../../common/banner/banner";
import bg3 from "../../../../images/bg/news_head2.jpg";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as DOMPurify from 'dompurify';
import DataLoader from "../../../common/dataLoader/dataLoader";
import PageName from "../../../common/pagename/pagename";

function NewsContent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const slug = useParams();
    if (slug.slug === "") navigate("/resources/news");

    const [loading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState(props.newsList);
    const [newsList2, setNewsList2] = useState(props.newsList);
    const [change, setChange] = useState(slug.slug);

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then((result)=> {
            getData(result.data);
        }).catch((e)=>{
            console.log(e)
        })

    }


    const getData = async (locData) => {
        await axios
            .get(`${serverLink}news/list/active`)
            .then(async (result) => {
                const data = result.data;
                if (data.filter(e=>e.Slug === slug.slug).length > 0){
                    let news_id = data.filter(e=>e.Slug === slug.slug)[0].EntryID;
                    let news_count = data.filter(e=>e.Slug === slug.slug)[0].ViewCount;
                    setNewsList(data.filter(e=>e.Slug === slug.slug));
                    setNewsList2(data);
                    props.setOnNewsDetails(data);
                    let sendData = {
                        EntryID: news_id,
                        ViewCount: parseInt(news_count),
                        userIP: locData.IPv4,
                        userCountry: locData.country_name,
                        lat: locData.latitude,
                        lng: locData.longitude,
                    }
                    await axios.patch(`${serverLink}news/update-view-count`, sendData).then(data => {setLoading(false);}).catch(err => {setLoading(false);console.log("Error Updating Count")});
                }else{
                    navigate("/resources/news")
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getLocationData();
    }, [""]);

    useEffect(() => {
        getLocationData();
    }, [change]);


    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, [location]);


    return (
        <>
            {loading ? (
                <section className="space-ptb position-relative">
                    <div className="container" style={{height: '400px'}}>
                        <div className="page-header d-print-none">
                            <DataLoader/>
                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <PageName page={"News Details"} />
                    <Banner
                        Image={bg3}
                        Title={"News Details"}
                        breadcrumb={["Home", "News", "Details"]}
                    />

                    <section className="mb-5"/>
                    <section className="space-ptb position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mb-4 mb-md-0">
                                    <div className="blog">
                                        <div className="blog-image">
                                            {
                                                newsList.length > 0 &&
                                                    !newsList[0]?.ImagePath || newsList[0]?.ImagePath === "" ?
                                                        <></>
                                                        :
                                                        <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '400px' , minHeight: "400px"}} src={`${serverLink}public/uploads/news_uploads/${newsList[0]?.ImagePath}`} alt=""/>
                                            }

                                        </div>
                                        <div className="blog-content border-0 mt-4  mb-4 ">
                                            <h5 className="blog-title mb-3" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsList.length > 0 ? newsList[0]?.Title : "")}}/>
                                            <div className="blog-meta mb-0">
                                                <ul className="list-unstyled mb-0 d-flex">
                                                    <li><i className="fas fa-calendar-alt"/>{formatDateAndTime(newsList[0]?.InsertedDate, "date")}</li>
                                                    <li><i className="fas fa-user"/> {newsList[0]?.InsertedBy}</li>
                                                </ul>
                                            </div>
                                            <p className="mt-3 mb-0 text-justify" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsList.length > 0 ? newsList[0]?.Description : "")}}/>

                                        </div>
                                        <hr/>
                                    </div>
                                    <div className="d-sm-flex justify-content-between mb-5">
                                        <div className="blog-post-meta pr-4">
                                            <a href="#" className="mr-3"><i className="far fa-eye pr-1"/>{newsList[0]?.ViewCount}</a>
                                        </div>
                                        <div className="social d-flex align-items-center">
                                            <p className="text-primary mb-0 pr-2">Share this post:</p>
                                            <a  href={projectFacebook} target="_blank"><i className="fab fa-facebook-f pr-3 text-primary"/></a>
                                            <a  href={projectTwitter} target="_blank"><i className="fab fa-twitter pr-3 text-primary"/></a>
                                            <a  href={projectInstagram} target="_blank"><i className="fab fa-instagram pr-3 text-primary"/></a>
                                            <a  href={projectLinkedIn} target="_blank"><i className="fab fa-linkedin text-primary"/></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 mb-5 mb-lg-0">
                                    <div className="blog-sidebar">
                                        <div className="widget">
                                            <div className="widget-title">
                                                <h5>Recent Post</h5>
                                            </div>
                                            {
                                                newsList2.length > 0 && newsList2.filter(e=>e.Slug !== slug.slug).sort(() => Math.random() - 0.4).map((items, key) => {
                                                    return(
                                                        <div key={key} className="media mb-3 pb-3 border-bottom">
                                                            <Link onClick={()=> {
                                                                setLoading(true);
                                                                setChange(items.Slug)
                                                            }} className="text-secondary" to={`/resources/news/${items.Slug}`}>
                                                                <img className="img-fluid img-thumbnail avatar border-radius w-100"  style={{maxHeight: '50px' , minHeight: "50px", maxWidth: '70px' , minWidth: "70px",}}  src={`${serverLink}public/uploads/news_uploads/${items.ImagePath}`} alt=""/></Link>
                                                            <div className="media-body ml-3">
                                                                <Link onClick={()=> {
                                                                    setLoading(true);
                                                                    setChange(items.Slug)
                                                                }} className="text-secondary" to={`/resources/news/${items.Slug}`}><b>{items.Title?.slice(0, 43) + (items.Title?.length > 43 ? "..." : "")}</b></Link>
                                                                <Link onClick={()=> {
                                                                    setLoading(true);
                                                                    setChange(items.Slug)
                                                                }} className="d-block date text-dark"  to={`/resources/news/${items.Slug}`}>{formatDateAndTime(items.InsertedDate, "date")}</Link>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        <div className="widget">
                                            <div className="widget-title">
                                                <h5>Follow on</h5>
                                            </div>
                                            <div className="social">
                                                <ul className="list-unstyled">
                                                    <li><a href={projectFacebook} target="_blank"> <i className="fab fa-facebook-f"/> </a></li>
                                                    <li><a href={projectInstagram} target="_blank"> <i className="fab fa-instagram"/> </a></li>
                                                    <li><a href={projectTwitter} target="_blank"> <i className="fab fa-twitter"/> </a></li>
                                                    <li><a href={projectLinkedIn} target="_blank"> <i className="fab fa-linkedin"/> </a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsContent);