import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PageName from "../../common/pagename/pagename";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {useNavigate} from "react-router-dom";
const CryptoJS = require("crypto-js");


function CreateStudentLogin(props) {
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        APPID: props.ApplicantData.APPID,
        USERNAME: props.ApplicantData.EMAIL,
        PAYCODE: props.ApplicantData.PAYCODE,
        PASSWORD: "",
        CONFIRM_PASSWORD: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.PASSWORD.toString().trim() === "") {
            showAlert("Empty Field", "Please enter new password", "error");
            return false;
        }

        if(formData.CONFIRM_PASSWORD.toString().trim() === "") {
            showAlert("Empty Field", "Please enter confirm password", "error");
            return false;
        }

        if(formData.PASSWORD.toString() !== formData.CONFIRM_PASSWORD.toString()) {
            showAlert("PASSWORD NOT MATCH", "Your password did not match. Please try again!", "error");
            return false;
        }

        if(formData.CONFIRM_PASSWORD.toString().trim().length < 7) {
            showAlert("Password length", "Password length cannot be less than 8 characters  ", "error");
            return false;
        }

        const sendData = {
            ...formData
        }

        toast.info("Creating your account, please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}application/admission/create-login`, sendData)
            .then(result => {
                const response = result.data.message;
                if (response === 'duplicate') {
                    showAlert("STUDENT ACCOUNT EXIST", "Account already exist. Kindly login through student portal login", "error");
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else if (response === 'password') {
                    showAlert("INVALID LOGIN CREDENTIALS", "Something went wrong, kindly change your password", "error");
                } else {
                    toast.success("Account Created Successful. Please wait while the system is redirecting ...");
                    if (sendData.PAYCODE === "APP"){
                        setTimeout(function () {
                            window.location.href = `https://kadpolyodfel.ng/application/applicant_login.php`
                        }, 2000)
                    }else{
                        setTimeout(function () {
                            navigate("/portal/student-login");
                        }, 2000)
                    }

                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    useEffect(()=> {
        if (!props.ApplicantData.APPID){
            navigate("/");
        }
    }, [])

    return (
        <>
            <PageName page={"Student Login"} />
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                                <h2>Create Student Login</h2>
                                <p>login to student portal with your email and the password you are about to create now</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2">
                            <form className="login bg-white shadow p-5">
                                <div className="form-group">
                                    <label>New Password </label>
                                    <input type="password" className="form-control" id="PASSWORD"  name="PASSWORD"   onChange={handleChange} placeholder="Password" />
                                    <span className="focus-border"/>
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password </label>
                                    <input type="password" className="form-control" id="CONFIRM_PASSWORD"  name="CONFIRM_PASSWORD"   onChange={handleChange} placeholder="Confirm Password" />
                                    <span className="focus-border"/>
                                </div>
                                <button type="submit" className="btn btn-dark mt-4 w-100"  onClick={handleSubmit}>CREATE LOGIN</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
const mapStateToProps = (state) => {
    return {
        ApplicantData: state.GeneralDetails,
    };
};

export default connect(mapStateToProps, null)(CreateStudentLogin);