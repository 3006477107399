import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import "./navigationbar.css";
import logo from "../../../images/logo.png";
import { setFacultyListDetails } from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import {
  projectEmail,
  projectFacebook, projectInstagram, projectLinkedIn,
  projectName,
  projectPhone, projectStaffURL, 
  projectTwitter,
} from "../../../resources/url";

function NavigationBar(props) {
  const location = useLocation();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
  });
  }, [location.pathname]);

  const dismissClick = () => {
    document.getElementById("ToggleNavBar").click();
  }

  return (
      <header className="header header-sticky">
        <div className="topbar bg-dark py-3 d-none d-lg-flex">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-none d-lg-flex align-items-center text-center">
                  <div className="mr-3 d-inline-block">
                    <a className="text-white" href={`mailto:${projectEmail}`}><i className="far fa-envelope mr-2"/><span>Email:</span> {projectEmail}</a>
                  </div>
                  <div className="mr-auto d-inline-block">
                    <a className="text-white" href={`tel:${projectPhone}`}><i className="fas fa-phone-alt mr-2"/><span>Call</span> {projectPhone}</a>
                  </div>

                  <div className="social-icon">
                    <ul className="social-icon">
                      <li>
                        <a href={projectFacebook}><i className="fab fa-facebook-f"/></a>
                      </li>
                      <li>
                        <a href={projectTwitter}><i className="fab fa-twitter"/></a>
                      </li>
                      <li>
                        <a href={projectLinkedIn}><i className="fab fa-linkedin-in"/></a>
                      </li>
                      <li>
                        <a href={projectInstagram}><i className="fab fa-instagram"/></a>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main py-3">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-lg-flex align-items-center">
                  <a className="" href="/" style={{fontSize: '12px'}}>
                    <img src={logo} alt={projectName} width={50} height={50}/>
                  </a>
                  <nav className="navbar navbar-expand-lg">
                    <button className="navbar-toggler" id="ToggleNavBar" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a href={"/"} className="nav-link">
                            Home
                          </a>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link  dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">Admission <i className="fas fa-chevron-down fa-xs"/>
                          </a>
                          <ul className="dropdown-menu" style={{width:"300px"}}>
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }} to={"/admission/courses"}  className="nav-link">Our Courses</Link></li>
                            <li className="dropdown-item"><a href={"https://application.kadpolyodfel.ng/apply_now.php"}  target="_blank" className="nav-link">Apply Now</a></li>
                            <li className="dropdown-item"><a href={"https://application.kadpolyodfel.ng/applicant_login.php"}  target="_blank" className="nav-link">Applicant Login</a></li>
                            <li className="dropdown-item"><Link  onClick={()=>{ dismissClick() }}  to={"/admission/check-admission-status"} className="nav-link">Check Admission Status</Link></li>
                            {/*<li className="dropdown-item"><Link  onClick={()=>{ dismissClick() }}  to={"/admission/check-payment"} className="nav-link">Check Payment Status</Link></li>*/}
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">Academics<i className="fas fa-chevron-down fa-xs"/>
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  to={"/academics/colleges"} className="nav-link">Colleges</Link></li>
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  to={"/academics/staff"} className="nav-link">Staff Directorate</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">Resources<i className="fas fa-chevron-down fa-xs"/>
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  to={"/resources/news"} className="nav-link">News</Link></li>
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  to={"/resources/gallery"} className="nav-link">Gallery</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">Portals<i className="fas fa-chevron-down fa-xs"/>
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  className="nav-link" to="/portal/student-login">Student Portal</Link></li>
                            <li className="dropdown-item"><a className="nav-link" href={projectStaffURL} target="_blank">Staff Portal</a></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">About<i className="fas fa-chevron-down fa-xs"/>
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item"><Link onClick={()=>{ dismissClick() }}  to={"/about-us"} className="nav-link">About Us</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item">
                          <Link onClick={()=>{ dismissClick() }}  to={"/contact-us"} className="nav-link">Contact Us</Link>
                        </li>
                      </ul>
                    </div>

                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
