import React from "react";
import { connect } from "react-redux";
import {Link} from "react-router-dom";

function CourseSide(props) {
    return (
        <div className="col-lg-3  mb-3">

            <div className="course-sidebar shadow">
                <div className="related-download">
                    <div className="widget">
                        <div className="widget-title">
                            <h5>Quick Links</h5>
                            <hr/>
                        </div>
                        <div className="widget-categories">
                            <ul className="list-unstyled list-style mb-0">
                                <li>
                                    <Link to={`/`}>
                                        <i className="flaticon-phone-call" />
                                        Home
                                        <i className="fa fa-angle-right float-right" />
                                    </Link>
                                </li>

                                <li>
                                    <Link to={`/academics/colleges`}>
                                        <i className="flaticon-email" />
                                        College
                                        <i className="fa fa-angle-right float-right" />
                                    </Link>
                                </li>

                                <li>
                                    <Link to={`/academics/staff`}>
                                        <i className="flaticon-email" />
                                        Staff Directorate
                                        <i className="fa fa-angle-right float-right" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        courseList: state.CourseListDetails,
    };
};

export default connect(mapStateToProps, null)(CourseSide);