import React, {useState} from "react";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function FindCourse(props) {
    const navigate = useNavigate();
    const [selectedCourse, setSelectedCourse] = useState('1');

    const handleCourseChange = (e) => {
      setSelectedCourse(e.target.value)
      if(e.target.value !== '1'){
        navigate(e.target.value)
      }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedCourse !== '1') {
            navigate(selectedCourse)
        } else {
            toast.info('Please select a course first');
        }
    }

    return (
        <section className="mt-md-n5 mt-4 position-relative z-index-9">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-4 bg-primary border-radius">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-7 col-md-8 align-self-center mb-4 mb-md-0">
                                            <h2>Search Course</h2>
                                            {/*<p className="mb-0 text-white">Now go push your own limits and succeed!</p>*/}
                                        </div>
                                        <div className="col-lg-5 col-md-4 justify-content-end align-self-center">
                                            <div className="subscribe-form">
                                                <form action="#">
                                                    <select className="form-control " onChange={handleCourseChange}>
                                                        <option value="1">Select Course</option>
                                                        {
                                                            props.departmentList.length > 0 &&
                                                            props.departmentList.map((course, index) => {
                                                                return <option key={index} value={`/admission/course/${course.Slug}`}>{course.DEPARTMENT}</option>
                                                            })
                                                        }
                                                    </select>
                                                        <a href="#"><i className="fas fa-long-arrow-alt-right"/></a>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        courses: state.CourseListDetails
    };
};

export default connect(mapStateToProps, null)(FindCourse);
