import React from "react";
import { Link } from "react-router-dom";
import {currencyConverter} from "../../../../resources/url";

export default function CourseContent(props) {
    return (
        <div className="course-sidebar col-lg-9">
            <div className="widget">
                <div className="widget-title">
                    <h5>Search Course</h5>
                </div>
                <form className="navbar-form search d-flex">
                    <input type="text" name="q" className="form-control input-search rounded-0 bg-white" placeholder="Search" onChange={props.onCourseChange} autoComplete="off" />
                    <a><i className="fa fa-search text-white" /></a>
                </form>
            </div>
            <div className="courses-left-content">
                {props.courseList.length > 0 ? (
                    <div className="row popular-courses">
                        {props.courseList.map((item, key) => {
                            return <div className="col-lg-6 col-md-6 mb-4 accordion" id="" key={key}>
                                <div key={key} className="card">
                                    <div className="card-header" id="headingEight">
                                        <h2 className="mb-0">
                                            <Link to={`/admission/course/${item.Slug}`} className="btn btn-link btn-block text-left d-flex justify-content-between" style={{backgroundColor: '#f8f6f6'}}>
                                                <span style={{color: '#454545'}}>{item.DEPARTMENT}</span>
                                            </Link>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse show" style={{backgroundColor: '#f8f6f6'}}>
                                        <Link to={`/admission/course/${item.Slug}`}>
                                            <div className="card-body">
                                                <a href="#" className="text-secondary"> College of {item.COLLEGE_NAME}</a>
                                                <p className="text-secondary"> Programmes: ND/HND</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                    </div>
                        })}

                    </div>
                ) : (
                    <h3>No Course Found</h3>
                )}

            </div>
        </div>
    );
}

