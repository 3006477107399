import React, {useState} from "react";
import { connect } from "react-redux";
import PageName from "../../common/pagename/pagename";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {
    APIKEY, CHECKSTATUSURL, currencyConverter,
    formatDateAndTime,
    GATEWAYRRRPAYMENTURL,
    MERCHANTID,
    serverLink,
} from "../../../resources/url";
import {useLocation, useNavigate} from "react-router-dom";
import DataLoader from "../../common/dataLoader/dataLoader";
import {setGeneralDetails} from "../../../actions/setactiondetails";
import text from "dompurify";
const CryptoJS = require("crypto-js");
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CheckPayment(props) {

    const navigate = useNavigate();
    let query = useQuery();
    let queryData = query.get("orderID");
    const [IsLoading, setIsLoading] = useState(false);
    const [IsChecking, setIsChecking] = useState(false);
    const [showAdmission, setShowAdmission] = useState(0);
    const [appData, setAppData] = useState({});
    const [appFormData, setAppFormData] = useState({APPID: ""});
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        PROG_ID: "",
        EMAIL: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MIDDLE_NAME: "",
        TELEPHONE: "",
        ADMISSION_TYPE: "",
        AMOUNT: 0,
        PAYMENT_TYPE: "",
        PAYMENT_DATE: "",
        PAYCODE: "",
        RRR: "",
        ORDER_ID: "",
        STATUS: "",
        new_hash: "",
    });

    const resetFormData = () => {
      setFormData({
          APPID: "",
          STUDENTID: "",
          PROG_ID: "",
          EMAIL: "",
          FIRST_NAME: "",
          LAST_NAME: "",
          MIDDLE_NAME: "",
          TELEPHONE: "",
          ADMISSION_TYPE: "",
          AMOUNT: 0,
          PAYMENT_TYPE: "",
          PAYMENT_DATE: "",
          PAYCODE: "",
          RRR: "",
          ORDER_ID: "",
          STATUS: "",
          new_hash: "",
      })
    }

    const handleChange = (e) => {
        setAppFormData({
            ...appFormData,
            [e.target.name]: e.target.value,
        })
    }
    const checkPaymentStatus = async (e) => {
        setIsChecking(true)
        try {
            e.preventDefault();
            if (appFormData.RRR.toString().trim() === "") {
                showAlert("Empty Field", "Please Enter Remita Reference Number (RRR)", "error");
                return false;
            }
            const rrr = appFormData.RRR;
            const apiHash = CryptoJS.SHA512(rrr + APIKEY + MERCHANTID);
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                }
            };

            const response = await axios.get(`${CHECKSTATUSURL}/${MERCHANTID}/${rrr}/${apiHash}/status.reg`, config);
            console.log(response)
            if (response.data.status.toString() === "00"){
               let order_id = response.data.orderId;
               let rrr_no = response.data.RRR;
                const res = await axios.get(`${serverLink}application/get-payment-data/${order_id}`);
                const AppData = res.data[0];
                console.log(AppData)
                props.setOnGeneralDetails(AppData);
                setAppData(AppData)
                setFormData({
                    ...formData,
                    APPID: AppData.APPID,
                    RRR: rrr_no,
                    ORDER_ID: order_id,
                    PROG_ID: AppData.PROG_ID,
                    EMAIL: AppData.EMAIL,
                    TELEPHONE: AppData.TELEPHONE,
                    FIRST_NAME: AppData.FIRST_NAME,
                    MIDDLE_NAME: AppData.MIDDLE_NAME,
                    LAST_NAME: AppData.LAST_NAME,
                    ADMISSION_TYPE: AppData.ADMISSION_TYPE,
                    AMOUNT: AppData.AMOUNT,
                    PAYMENT_TYPE: AppData.PAYMENT_TYPE,
                    PAYCODE: AppData.PAYCODE,
                    PAYMENT_DATE: AppData.DATE,
                    STATUS: AppData.STATUS,
                })
                setShowAdmission(1)
                setIsLoading(false)
                setIsChecking(false)
            }else{
                setShowAdmission(2)
                setIsLoading(false)
                setIsChecking(false)
            }
        } catch (error) {
            console.error(error);
            setShowAdmission(2)
            setIsLoading(false)
            setIsChecking(false)
            // showAlert(
            //     "NETWORK ERROR",
            //     "Please check your network connection and try again!",
            //     "error"
            // );
        }
    };
    const InitializePayment = async (e) => {
        e.preventDefault();
        let sendData = {
            ...formData,
        }
        await axios.post(`${serverLink}application/validate-payment`, sendData)
            .then(result => {
                const response = result.data.message;
                const PAYCODE = result.data.PAYCODE;
                if (response === 'success') {
                    toast.success("Payment Validated successfully");
                    if (PAYCODE === "APP") {
                        setTimeout(() => {
                            navigate("/admission/create-login");
                        }, 2000);
                    } else if (PAYCODE === "ACC"){
                        setTimeout(() => {
                            navigate("/admission/create-login");
                        }, 2000);
                    }else{
                        setTimeout(() => {
                            navigate("/portal/student-login");
                        }, 2000);
                    }
                    setShowAdmission(0)
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }
    return (
        <>
            <PageName page={"Check Admission"}/>
            <section className="space-ptb border-top" key={formData?.RRR}>
                <div className="container" style={{marginTop: '-30px'}}>
                    {
                        IsChecking ?
                            <div className="row justify-content-center">
                                <div className="col-lg-7 mb-2 text-center" style={{height: '200px'}}>
                                    <p>Please wait while the system is verifying your payment</p>
                                    <DataLoader/>
                                </div>
                            </div>
                            :
                            <>
                                <div className="row justify-content-center">
                                    { showAdmission.toString() === "0" ?
                                        <>
                                            <div className="col-xl-8 text-center">
                                                <div className="section-title">
                                                    <h2>Check Payment Status</h2>
                                                    <p>Use your remita reference number to check your payment status</p>
                                                </div>
                                            </div>
                                        </> : <></>

                                    }
                                </div>
                                {
                                    showAdmission.toString() === "0" ?
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 mb-2">
                                                <form className="login bg-white shadow p-5">
                                                    <div className="form-group">
                                                        <label>Remita Reference Number (RRR)</label>
                                                        <input type="text" className="form-control" id="RRR" name="RRR"
                                                               onChange={handleChange} placeholder="Enter Remita Reference (RRR)"/>
                                                        <span className="focus-border"/>
                                                    </div>

                                                    <button type="submit" className="btn btn-dark mt-3 w-100"
                                                            onClick={checkPaymentStatus}>CHECK
                                                    </button>
                                                </form>

                                            </div>
                                        </div>
                                        : showAdmission.toString() === "1" ?
                                            <div className="row justify-content-center">
                                                <div className="col-lg-7 mb-2">
                                                    <div className="login  shadow">
                                                        <div className="col-md-12 ">
                                                            <h4 className="text-center"><b>Payment Details</b></h4>
                                                            <hr/>
                                                            <table className="table w-100" style={{fontSize: '12px'}}>
                                                                <tbody>
                                                                <tr>
                                                                    <th>Remita (RRR):</th>
                                                                    <td>{formData?.RRR}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Application No.:</th>
                                                                    <td>{formData?.APPID}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Applicant Name:</th>
                                                                    <td>{formData?.FIRST_NAME} {formData?.MIDDLE_NAME} {formData?.LAST_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Email Address:</th>
                                                                    <td>{formData?.EMAIL}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Phone Number:</th>
                                                                    <td>{formData?.TELEPHONE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Type:</th>
                                                                    <td>{formData?.PAYMENT_TYPE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Amount Paid:</th>
                                                                    <td>{currencyConverter(formData?.AMOUNT)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Date/Time:</th>
                                                                    <td>{formatDateAndTime(formData?.PAYMENT_DATE, 'date_and_time')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status:</th>
                                                                    <td className="text-success">Successful</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {
                                                            formData?.STATUS === "Active"  ||  formData?.PAYCODE === "APP" ? <></> :
                                                                <div className="col-md-6 offset-sm-3 mb-4">
                                                                    <button type="button" className="btn btn-primary form-control"
                                                                            onClick={InitializePayment} name="btn_pay_acceptance"> VALIDATE PAYMENT
                                                                    </button>
                                                                </div>
                                                        }

                                                        <br/>
                                                    </div>
                                                </div>
                                            </div>
                                            :  showAdmission.toString() === "2" ?
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-7 mb-2">
                                                        <form className="login bg-white shadow p-5">
                                                            <div className="alert alert-danger text-center mb-2">
                                                                <h3 style={{color: '#7e2626'}}> Invalid Payment!</h3>
                                                            </div>

                                                            <button type="submit" className="btn btn-dark mt-3 w-100"
                                                                    onClick={resetFormData}>CHECK AGAIN
                                                            </button>
                                                        </form>

                                                    </div>
                                                </div>
                                                : <></>
                                }

                            </>

                    }



                </div>
            </section>
            <form action={GATEWAYRRRPAYMENTURL} method="POST">
                <input id="merchantId" name="merchantId" value={MERCHANTID} type="hidden"/>
                <input id="rrr" name="rrr" value={formData.RRR} type="hidden"/>
                <input id="responseurl" name="responseurl" value={window.location.href} type="hidden"/>
                <input id="hash" name="hash" value={formData.new_hash} type="hidden"/>
                <button type="submit" id="pay">PAY</button>
            </form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
        ApplicantData: state.GeneralDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPayment);