import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PageName from "../../common/pagename/pagename";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {
    APIKEY, CHECKSTATUSURL, currencyConverter,
    formatDateAndTime,
    GATEWAYRRRPAYMENTURL, GATEWAYURL, generate_token,
    MERCHANTID, paymentAPIKey,
    serverLink, SERVICETYPEID,
} from "../../../resources/url";
import {useLocation, useNavigate} from "react-router-dom";
import DataLoader from "../../common/dataLoader/dataLoader";
import { setGeneralDetails} from "../../../actions/setactiondetails";
import {usePaystackPayment} from "react-paystack";

const CryptoJS = require("crypto-js");

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CheckAdmission(props) {
    const navigate = useNavigate();
    let query = useQuery();
    let queryData = query.get("orderID");
    const [IsLoading, setIsLoading] = useState(false);
    const [showAdmission, setShowAdmission] = useState(0);
    const [appData, setAppData] = useState({});
    const [appFormData, setAppFormData] = useState({APPID: ""});
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        PROG_ID: "",
        EMAIL: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MIDDLE_NAME: "",
        TELEPHONE: "",
        ADMISSION_TYPE: "",
        AMOUNT:  0,
        RRR: "",
        new_hash: "",
        TransID: "",
        EmailAddress: "itri@bazeuniversity.edu.ng",
        Amount: 0,
    });

    const config = {
        reference: "ODFeL-"+generate_token(10),
        email: formData.EMAIL,
        amount: formData.AMOUNT * 100,
        publicKey: paymentAPIKey,
    };


    const handleChange = (e) => {
        setAppFormData({
            ...appFormData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (appFormData.APPID.toString().trim() === "") {
            showAlert("Empty Field", "Please Enter Application/Jamb No.", "error");
            return false;
        }

        const sendData = {...appFormData}

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}application/admission/status`, sendData)
            .then(result => {
                const response = result.data.message;
                const AppData = result.data.appData;
                if (response === 'invalid') {
                    showAlert("NO ADMISSION", "You have not been offered admission yet!. Kindly check back later", "error");
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    props.setOnGeneralDetails(AppData);
                    setAppData(AppData)
                    setFormData({
                        ...formData,
                        APPID: AppData.APPID,
                        PROG_ID: AppData.PROG_ID,
                        EMAIL: AppData.EMAIL,
                        TELEPHONE: AppData.TELEPHONE,
                        FIRST_NAME: AppData.FIRST_NAME,
                        MIDDLE_NAME: AppData.MIDDLE_NAME,
                        LAST_NAME: AppData.LAST_NAME,
                        ADMISSION_TYPE: AppData.ADMISSION_TYPE,
                        AMOUNT: AppData.ACCEPT_FEE,
                    })
                    setShowAdmission(1)
                    // toast.success("You have been offered provisional admission");
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })
    }

    const generateInvoice = async (e) => {
        e.preventDefault();
        if (appFormData.APPID.toString().trim() === "") {
            showAlert("Empty Field", "Please Enter Application/Jamb No.", "error");
            return false;
        }

        const sendData = {...appFormData}

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}application/admission/status`, sendData)
            .then(result => {
                const response = result.data.message;
                const AppData = result.data.appData;
                if (response === 'invalid') {
                    showAlert("NO ADMISSION", "You have not been offered admission yet!. Kindly check back later", "error");
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    setAppData(AppData)
                    setFormData({
                        ...formData,
                        APPID: AppData.APPID,
                        PROG_ID: AppData.PROG_ID,
                        EMAIL: AppData.EMAIL,
                        TELEPHONE: AppData.TELEPHONE,
                        FIRST_NAME: AppData.FIRST_NAME,
                        MIDDLE_NAME: AppData.MIDDLE_NAME,
                        LAST_NAME: AppData.LAST_NAME,
                        ADMISSION_TYPE: AppData.ADMISSION_TYPE,
                    })
                    setShowAdmission(1)
                    // toast.success("You have been offered provisional admission");
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    const makeRemitaPayment = () => {
        let merchantId = MERCHANTID;
        let apiKey = APIKEY;
        let serviceTypeId = SERVICETYPEID;
        let d = new Date();
        let orderId = d.getTime();
        let totalAmount = parseFloat(appData.ACCEPT_FEE) + 260;
        let apiHash = CryptoJS.SHA512(merchantId + serviceTypeId + orderId + totalAmount + apiKey);
        let req = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
            },
            body: JSON.stringify({
                "serviceTypeId": serviceTypeId,
                "amount": totalAmount,
                "orderId": orderId,
                "payerName": appData?.FIRST_NAME +" "+ appData?.MIDDLE_NAME +" "+  appData?.LAST_NAME,
                "payerEmail": appData?.EMAIL,
                "payerPhone": appData?.TELEPHONE,
                "description": "ACCEPTANCE FEE",
            }),
        };
        toast.info("Please wait...");
        fetch(`${GATEWAYURL}`, req)
            .then((response) => response.json())
            .then(async (responseJson) => {
                if (responseJson.statuscode.toString() === "025") {
                    let RRR = responseJson.RRR;
                    let new_hash_string = MERCHANTID + RRR + APIKEY;
                    let new_hash = CryptoJS.SHA512(new_hash_string);
                    let sendData = {
                        ...formData,
                        RRR: RRR,
                        orderId: orderId
                    }
                    setFormData({...formData, RRR: RRR, new_hash: new_hash})
                    await axios.post(`${serverLink}application/admission/acceptance/invoice`, sendData)
                        .then(result => {
                            const response = result.data.message;
                            if (response === 'error') {
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            } else {
                                setShowAdmission(2)
                                // toast.success("You have been offered provisional admission");
                            }
                            setIsLoading(false)
                        })
                        .catch(error => {
                            console.log(error)
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your network connection and try again!",
                                "error"
                            );
                            setIsLoading(false)
                        })
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }
    const makePaystackPayment = async () => {
        toast.info("Please wait...");
        let year = new Date().getFullYear().toString().substr(-2);
        let TransID =  year+generate_token(10);
        let sendData = {
            ...formData,
            TransID: TransID
        }
        setFormData({...formData, TransID: TransID})
        await axios.post(`${serverLink}application/admission/acceptance/paystack/invoice`, sendData)
            .then(result => {
                const response = result.data.message;
                if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    setShowAdmission(2)
                }
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })
    }

    const checkPaymentStatus = async () => {
        try {
            const rrr = query.get("RRR");
            const orderID = query.get("orderID");
            const apiHash = CryptoJS.SHA512(orderID + APIKEY + MERCHANTID);

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                }
            };

            const response = await axios.get(`${CHECKSTATUSURL}/${MERCHANTID}/${orderID}/${apiHash}/orderstatus.reg`, config);

            if (response.data.status.toString() === "00") {
                const sendData = {
                    APPID: props.ApplicantData.APPID,
                    PROG_ID: props.ApplicantData.PROG_ID,
                    RRR: response.data.RRR,
                    ORDER_ID: response.data.orderId,
                };

                const result = await axios.patch(`${serverLink}application/admission/acceptance/payment`, sendData);
                const { message, isCreated } = result.data;

                if (message === 'error') {
                    showAlert(
                        "ERROR",
                        "Your payment was received successfully. But something went wrong updating your record. Kindly email us your evidence of payment",
                        "error"
                    );
                } else {
                    toast.success("Payment successful. Please wait while the system is redirecting...");
                    if (isCreated) {
                        setTimeout(() => {
                            navigate("/admission/create-login");
                        }, 2000);
                    } else {
                        setTimeout(() => {
                             navigate("/admission/create-login");
                        }, 2000);
                    }
                }

                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your network connection and try again!",
                "error"
            );
        }
    };
    const checkPaystackPaymentStatus = async (response) => {
        try {
            const sendData = {
                APPID: props.ApplicantData.APPID,
                PROG_ID: props.ApplicantData.PROG_ID,
                RRR: response.trxref,
                TransID: formData.TransID,
            };

            const result = await axios.patch(`${serverLink}application/admission/paystack/acceptance/payment`, sendData);
            const { message, isCreated } = result.data;

            if (message === 'error') {
                showAlert(
                    "ERROR",
                    "Your payment was received successfully. But something went wrong updating your record. Kindly email us your evidence of payment",
                    "error"
                );
            } else {
                toast.success("Payment successful. Please wait while the system is redirecting...");
                if (isCreated) {
                    setTimeout(() => {
                        navigate("/portal/student-login");
                    }, 2000);
                } else {
                    setTimeout(() => {
                        navigate("/admission/create-login");
                    }, 2000);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your network connection and try again!",
                "error"
            );
        }
    };

    const onSuccess =  (reference) => {
        checkPaystackPaymentStatus(reference);
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const paymentInitialize = () => {
        initializePayment(onSuccess, onClose)
    }

    // useEffect(() => {
    //     if (queryData){
    //         checkPaymentStatus();
    //     }
    // }, [!queryData ? null : queryData])

    return (
        <>
            <PageName page={"Check Admission"}/>
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        {
                            !queryData ?
                                showAdmission.toString() === "2" ? <></> :
                                <div className="col-xl-8 text-center">
                                    <div className="section-title">
                                        <h2>Check Admission Status</h2>
                                        <p>Use your application or jamb number to check your admission status</p>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                    </div>
                    {
                        !queryData ?
                            showAdmission.toString() === "1" ?
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 mb-2">
                                        <div className="login  shadow p-5 ">
                                            <div className="col-md-12  alert alert-success">
                                                <h4 className="text-center"><b>Congratulations!</b></h4>
                                                <p className="text-center"><b>You have been offered provisional
                                                    admission</b></p>
                                                <hr/>
                                                <table className="table w-100" style={{fontSize: '12px'}}>
                                                    <tbody>
                                                    <tr>
                                                        <th>Applicant Name:</th>
                                                        <td>{appData?.FIRST_NAME} {appData?.MIDDLE_NAME} {appData?.LAST_NAME}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Programme Applied:</th>
                                                        <td>{appData?.PROGRAMME}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Programme Type:</th>
                                                        <td>{appData?.PROGRAMME_NATURE}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Entry Level:</th>
                                                        <td>{appData?.ENTRY_LEVEL}</td>
                                                    </tr>
                                                     <tr>
                                                        <th>Session:</th>
                                                        <td>{appData?.ADMISSION_SESSION}</td>
                                                     </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6 offset-sm-3">
                                                <button type="button" className="btn btn-primary form-control"
                                                        onClick={makePaystackPayment} name="btn_pay_acceptance"> PAY
                                                    ACCEPTANCE FEE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                showAdmission.toString() === "2" ?
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 mb-2">
                                            <div className="login  shadow ">
                                                <div className="col-md-12 ">
                                                    <h4 className="text-center"><b>Payment Invoice</b></h4>
                                                    <hr/>
                                                    <table className="table w-100" style={{fontSize: '12px'}}>
                                                        <tbody>
                                                        <tr>
                                                            <th>Transaction Ref.:</th>
                                                            <td>{formData?.TransID}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Application No.:</th>
                                                            <td>{formData?.APPID}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Applicant Name:</th>
                                                            <td>{formData?.FIRST_NAME} {formData?.MIDDLE_NAME} {formData?.LAST_NAME}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email Address:</th>
                                                            <td>{formData?.EMAIL}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone Number:</th>
                                                            <td>{formData?.TELEPHONE}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Payment Type:</th>
                                                            <td>ACCEPTANCE FEE</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Amount Payable:</th>
                                                            <td>{currencyConverter(formData?.AMOUNT)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Date/Time Generated:</th>
                                                            <td>{formatDateAndTime(Date.now(), 'date_and_time')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-6 offset-sm-3 mb-4">
                                                    <button type="button"  id="pay" className="btn btn-primary form-control"
                                                            onClick={() => {
                                                                paymentInitialize()
                                                            }} name="btn_pay_acceptance"> PAY
                                                        NOW
                                                    </button>
                                                </div>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 mb-2">
                                            <form className="login bg-white shadow p-5">
                                                <div className="form-group">
                                                    <label>Application or Jamb Number</label>
                                                    <input type="text" className="form-control" id="APPID" name="APPID"
                                                           onChange={handleChange} placeholder="Application or Jamb"/>
                                                    <span className="focus-border"/>
                                                </div>

                                                <button type="submit" className="btn btn-dark mt-3 w-100"
                                                        onClick={handleSubmit}>CHECK
                                                </button>
                                            </form>

                                        </div>
                                    </div>
                            :
                            <div className="row justify-content-center">
                                <div className="col-lg-7 mb-2 text-center" style={{height: '200px'}}>
                                    <p>Please wait while the system is verifying your payment</p>
                                    <DataLoader/>
                                </div>
                            </div>
                    }
                </div>
            </section>
            {/*<form action={GATEWAYRRRPAYMENTURL} method="POST">*/}
            {/*    <input id="merchantId" name="merchantId" value={MERCHANTID} type="hidden"/>*/}
            {/*    <input id="rrr" name="rrr" value={formData.RRR} type="hidden"/>*/}
            {/*    <input id="responseurl" name="responseurl" value={window.location.href} type="hidden"/>*/}
            {/*    <input id="hash" name="hash" value={formData.new_hash} type="hidden"/>*/}
            {/*    <button type="submit" id="pay">PAY</button>*/}
            {/*</form>*/}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
        ApplicantData: state.GeneralDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAdmission);