import React from "react";
import WhyBG from "../../../images/bg/why.jpg"
import Pattern from "../../../images/about/pattern.png"

function WhyChooseUs() {
    return (
        <section className="bg-dark">
            <div className="container-fluid">
                <div className="row no-guuters">
                    <div className="col-lg-6 bg-holder d-none d-lg-block"
                         style={{backgroundImage: `url(${WhyBG})`}}>
                    </div>
                    <div className="col-lg-6 px-4 px-md-5 px-lg-6 py-6 px-lg-6"
                         style={{backgroundImage: `url(${Pattern})`}}>
                        <div className="section-title">
                            <h2 className="title">Why Choose Kadpoly ODFeL?</h2>
                            {/*<p className="mb-0">Let success motivate you. Find a picture of what epitomizes success to*/}
                            {/*    you and then pull it out when you are in need of motivation.</p>*/}
                        </div>
                        <div className="media">
                            <i className="flaticon-knowledge mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">Flexibility</h5>
                                <p className="text-white mb-0">Study from anywhere at any time; Attend to your job or family while studying at your own pace.</p>
                            </div>
                        </div>
                        <span className="border-bottom d-block my-5 border-light"></span>
                        <div className="media">
                            <i className="flaticon-mortarboard-2 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">Affordability</h5>
                                <p className="text-white mb-0">Affordable tuition and flexible payment methods.</p>
                                <p className="text-white mb-0">Same Kadpoly certificate.</p>
                            </div>
                        </div>
                        <span className="border-bottom d-block my-5 border-light"></span>
                        <div className="media">
                            <i className="flaticon-team-1 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
                            <div className="media-body">
                                <h5 className="mt-0 mb-3">Easy Access To Course Contents</h5>
                                <p className="text-white mb-0">24 hours and everyday access to lectures and course contents.</p>
                                <p className="text-white mb-0">Uninterrupted academic calendar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs;
