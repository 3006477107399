import React from "react";
import {projectAddress, projectEmail, projectPhone} from "../../resources/url";
import bg from "../../images/contact.jpg";
import Banner from "../common/banner/banner";
import PageName from "../common/pagename/pagename";

function Contact() {
    return (
        <>
            <PageName page={"Contact Us"} />
            <Banner
                Image={bg}
                Title={"Contact Us"}
                breadcrumb={["Home", "Contact Us"]}
            />
            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="text-center  mb-3 bg-light p-4 border-radius mb-4" style={{minHeight: '220px'}}>
                                        <div className="text-primary mr-3 mb-4">
                                            <i className="fas fa-5x fa-map-signs"/>
                                        </div>
                                        <div className="recent-post-info">
                                           <span className="text-black">{projectAddress}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-center  mb-3 bg-light p-4 border-radius mb-4" style={{minHeight: '220px'}}>
                                        <div className="text-primary mr-3 mb-4">
                                            <i className="far fa-5x fa-envelope"/>
                                        </div>
                                        <div className="recent-post-info">
                                            <span>{projectEmail}<br/> Mon-Fri 8:30am-6:30pm</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-5">
                                    <div className="text-center  bg-light p-4 border-radius " style={{minHeight: '220px'}}>
                                        <div className="text-primary mr-3 mb-4">
                                            <i className="fas fa-5x fa-headphones-alt"/>
                                        </div>
                                        <div className="recent-post-info">
                                            <span className="text-black">{projectPhone}<br/> 24 X 7 online support</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-4 mb-lg-0">
                            <div className="container-fluid p-0 table-responsive-md">
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="map h-500">
                                            <iframe
                                                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=ODFel Kaduna Polytechnic&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                                width="100%" height="450" style={{border:0}} allowFullScreen=""
                                                loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;