import React from "react";
import { Spinner } from "react-bootstrap";
import {ThreeDots} from "react-loader-spinner";
function DataLoader() {
  return (
   <div className="space-ptb text-center table-responsive-md mb-4" style={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
   }}>
       <ThreeDots
           height="80"
           width="80"
           radius="9"
           color="green"
           ariaLabel="loading"

       />
   </div>
  );
}


export default DataLoader;
