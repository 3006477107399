import React from "react";

function StatementSection() {
    return (
        <section className="space-ptb  bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-title text-center">
                            <h2>Read our Statements</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 pb-4 pb-md-0" >
                        <div className="pricing" style={{minHeight: '330px'}}>
                            <h4 className="pricing-title">VISION</h4>
                            <p className="mb-0">A best-value, first-choice Polytechnic offering signature programmes that prepare students for the changing needs of the workplace's</p>
                        </div>
                    </div>
                    <div className="col-md-4 pb-4 pb-md-0">
                        <div className="pricing" style={{minHeight: '330px'}}>
                            <h4 className="pricing-title">MISSION</h4>
                            <p className="mb-0">To be an innovative and entrepreneurial institution of repute, empowering people to compete successfully in the workplace by providing high impact technology-oriented education, training, services and needs-driven research.</p>
                        </div>
                    </div>
                    <div className="col-md-4 pb-4 pb-md-0">
                        <div className="pricing" style={{minHeight: '330px'}}>
                            <h4 className="pricing-title">OUR CORE VALUES</h4>
                            <p className="mb-0">Academic Excellence Innovation and Creativity Dedication to Duty Integrity, Accountability and Transparency Meritocracy: Performance-based Recognition and Reward System Leadership and Mentorship Social Responsibility</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StatementSection;
