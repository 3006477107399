import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import Banner from "../common/banner/banner";
import bg from "../../images/about_logo.jpg";
import kadpoly from "../../images/kadpoly.jpg";
import { missionStatement, visionStatement } from "../../resources/url";
import LearningCentersSection from "../homepage/section/learning-section";
import GallerySection from "../homepage/section/gallery-section";
import StatementSection from "../homepage/section/statement-section";



function About(props) {
    return (
        <>
            <PageName page={"About Us"} />
            <Banner
                Image={bg}
                Title={"About Us"}
                breadcrumb={["Home", "About Us"]}
            />
            <div className="academics-details-area pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 " style={{backgroundImage: `url(${kadpoly})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '300px', width: '100%'}}></div>
                        <div className="col-lg-6">
                            <div className="academics-details">
                                <div className="details">
                                    <p  style={{ textAlign: 'justify' }}
                                    >
                                        <h4>About ODFeL Kaduna Polytechnic</h4>
                                        In the year 2020, the National Board for Technical Education initiated the Open Distance and Flexible e-Learning (ODFel) platform due to the effect of the Covid-19 pandemic on the traditional learning system and also on the need to fill the gap created by limited campus space for regular students by leveraging on available internet based eLearning
                                    </p>
                                    <p className="mt-4" style={{textAlign: 'justify'}}>
                                        The ODFeL program at Kaduna Polytechnic is designed to provide flexible learning opportunities to individuals who are unable to pursue traditional on-campus education due to various constraints. It aims to make education accessible to a wider range of learners, including working professionals, individuals residing in remote areas, and those with other commitments.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h4 className="mt-4 text-primary">Utility</h4>
                            <p style={{textAlign: 'justify'}}>
                                The program utilizes online learning platforms, digital resources, and interactive technologies to deliver educational content and facilitate learning. Students enrolled in ODFeL programs at Kaduna Polytechnic can access course materials, participate in virtual classes, engage in discussions, submit assignments, and interact with instructors and fellow students through the online platform.
                            </p>
                            <h4 className="mt-4 text-primary">Flexibility</h4>
                            <p style={{textAlign: 'justify'}}>
                                The ODFeL program at Kaduna Polytechnic provides a flexible learning environment where students can study at their own pace and convenience. They have the flexibility to choose when and where they engage with the course materials, allowing them to balance their education with work, family responsibilities, and other commitments.
                            </p>

                            <h4 className="mt-4 text-primary">Support</h4>
                            <p style={{textAlign: 'justify'}}>
                                To support the learning process, ODFeL Kaduna Polytechnic provides a range of online resources, including e-books, video lectures, multimedia presentations, and interactive learning modules. These resources are easily accessible and can be accessed from any location with an internet connection, enabling students to study at their own convenience.
                            </p>
                            <p style={{textAlign: 'justify'}}>

                                ODFeL Kaduna Polytechnic is committed to quality education and employs qualified instructors and subject matter experts who facilitate the learning process and provide guidance and support to students. The program also incorporates regular assessments and examinations to evaluate students' progress and ensure academic rigor.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <StatementSection/>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(About);