import { projectCode } from "../resources/url";
import { setHomepageContentDetails } from "../actions/setactiondetails";

export const FacultyListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_FACULTY_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const DepartmentListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_DEPARTMENT_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};


export const CourseListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_COURSE_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const StaffListDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_STAFF_LIST_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};


export const NewsDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_NEWS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const GeneralDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case `SET_${projectCode}_GENERAL_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const HomepageContentDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const ProgrammeDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PROGRAMME_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const SliderDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HOME_SLIDER_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const SchoolsDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_SCHOOLS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const GalleryDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_GALLERY_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};
