import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setStaffListDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import {
    serverLink
} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import * as DOMPurify from 'dompurify';
import DataLoader from "../../common/dataLoader/dataLoader";
import PageName from "../../common/pagename/pagename";

function StaffDetails(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const slug = useParams();
    if (slug.slug === "") navigate("/academics/staff");

    const [loading, setLoading] = useState(true);
    const [staffList, setStaffList] = useState(props.staffList);


    const getLocationData = async () => {
       await axios.get('https://geolocation-db.com/json/').then((result)=>{
           console.log(result.data)
            getData(result.data);
        }).catch((e)=>{
            console.log("error", e);
        })

    }


    const getData = async (locData) => {
        await axios
            .get(`${serverLink}staff/website/list/active`)
            .then(async (result) => {
                const data = result.data;
                if (data.filter(e=>e.Slug === slug.slug).length > 0){
                    let staff_id = data.filter(e=>e.Slug === slug.slug)[0].StaffID;
                    let staff_count = data.filter(e=>e.Slug === slug.slug)[0].Hits;
                    setStaffList(data.filter(e=>e.Slug === slug.slug));
                    props.setOnStaffDetails(data);
                    let sendData = {
                        StaffID: staff_id,
                        Hits: parseInt(staff_count),
                        userIP: locData.IPv4,
                        userCountry: locData.country_name,
                        lat: locData.latitude,
                        lng: locData.longitude,
                    }
                    await axios.patch(`${serverLink}staff/website/update_profile_hit`, sendData).then(data => {setLoading(false);}).catch(err => {setLoading(false);console.log("Error Updating Count")});
                }else{
                    navigate("/academics/staff")
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getLocationData();
    }, [""]);


    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, [location]);

    return (
        <>
            {loading ? (
                <section className="space-ptb position-relative">
                    <div className="container" style={{height: '400px'}}>
                        <div className="page-header d-print-none">
                            <DataLoader/>
                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <PageName page={staffList.length > 0 ? staffList[0]?.StaffName : "Staff Details"} />
                    <Banner
                        Image={!staffList[0]?.Image ? staffList[0]?.Gender === "Male" ? require('../../../images/male.jpg')  : require('../../../images/female.jpg') : `${serverLink}public/uploads/staff_uploads/${staffList[0]?.Image}`}
                        Title={staffList[0]?.StaffName}
                        breadcrumb={["Home", "Staff", "Details"]}
                    />

                    <section className="mb-5"/>
                    <section className="space-ptb position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="row">
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <div className="team-single is-sticky">
                                            <div className="team-img col-md-12">
                                                {
                                                    staffList.length > 0 &&
                                                    !staffList[0]?.Image || staffList[0]?.Image === "" ?
                                                        <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '320px' , minHeight: "320px"}} src={staffList[0]?.Gender === "Male" ? require('../../../images/male.jpg')  : require('../../../images/female.jpg')} alt=""/>
                                                    :
                                                        <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '320px' , minHeight: "320px"}} src={`${serverLink}public/uploads/staff_uploads/${staffList[0]?.Image}`} alt=""/>
                                                }
                                            </div>
                                            <div className="social mt-4 text-center">
                                                <ul className="pl-3">
                                                    <li><a href={staffList[0]?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href={staffList[0]?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a> </li>
                                                    <li><a href={staffList[0]?.Linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href={staffList[0]?.Scholar} target="_blank"><i className="fab fa-google"></i></a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="team-info pl-3">
                                            <a href="#" className="team-name h4">{staffList[0]?.StaffName}</a>
                                            <h6 className="border-bottom text-secondary my-3 pb-3">{staffList[0]?.Designation}</h6>
                                            <div className="d-flex">
                                                <h6>Phone Number:</h6>
                                                <label className="ml-3">{staffList[0]?.PhoneNumber}</label>
                                            </div>
                                            <div className=" d-flex">
                                                <h6>Email Address:</h6>
                                                <label className="ml-3">{staffList[0]?.EmailAddress}</label>
                                            </div>
                                            <div className=" d-flex">
                                                <h6>Department:</h6>
                                                <label className="ml-3">{staffList[0]?.DepartmentName}</label>
                                            </div>
                                            <div className=" d-flex mb-5">
                                                <h6>Profile Hit <i className="fa fa-eye"/>:</h6>
                                                <label className="ml-3">{staffList[0]?.Hits}</label>
                                            </div>
                                            <div className=" d-flex"> <h4>Biography</h4></div>
                                            <p className="mt-2 text-justify"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(staffList.length > 0 ? staffList[0]?.Biography : "")}}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        staffList: state.StaffListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails);