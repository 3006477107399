import React, {useEffect} from "react";
import slider1 from "../../../images/bg/slider1.jpg";
import slider3 from "../../../images/bg/slider2.jpg";
import {serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
function Slider(props) {

    useEffect(()=> {
        const interval = setInterval(() => {
            document.getElementById("next-btn").click();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

  return (
      <section className="banner position-ralative">
          <div id="main-slider" className="swiper-container h-800 h-lg-700 h-md-600 h-sm-400">
              <div className="swiper-wrapper">
                  {
                      props.sliderList.length > 0 && props.sliderList.map((item, index)=>{
                          return(
                              <div key={index} className="swiper-slide align-items-center d-flex bg-overlay-black-40" style={{backgroundImage: `url(${serverLink}public/uploads/sliders_uploads/${item.ImagePath})`, backgroundSize: 'cover', backgroundPosition: 'center center',}}>
                                  <div className="swipeinner container">
                                      <div className="row justify-content-center text-center">
                                          <div className="col-lg-10 col-md-12">
                                              <div className="slider-1">
                                                  <div className="animated" data-swiper-animation="fadeInUp" data-duration="1s"
                                                       data-delay="0.25s">
                                                      <h1 className="animated text-white mb-4" data-swiper-animation="fadeInUp"
                                                          data-duration="1.5s" data-delay="0.25s">{item.Title}</h1>
                                                      <div className="animated text-white px-lg-5" data-swiper-animation="fadeInUp"
                                                           data-duration="2.5s" data-delay="0.25s">
                                                          <p className="d-none d-sm-block">{item.Caption}</p>
                                                      </div>
                                                      <Link to="/about-us" className="animated4 btn btn-white mt-4 mr-1 mr-sm-2"
                                                         data-swiper-animation="fadeInUp" data-duration="3.5s" data-delay="0.25s">Read
                                                          More<span></span></Link>
                                                      {
                                                          !item.BtnName || item.BtnName !=="" ?
                                                              <a href={item.BtnLink} className="animated4 btn btn-primary mt-4 ml-1 ml-sm-2"
                                                                 data-swiper-animation="fadeInUp" data-duration="3.5s" data-delay="0.25s">{item.BtnName}<span></span></a>
                                                              :
                                                              <></>
                                                      }

                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
          <div className="swiper-button-prev text-white"><i className="fa fa-arrow-left"/></div>
          <div className="swiper-button-next text-white" id="next-btn"><i className="fa fa-arrow-right"/></div>
      </section>
  );
}

export default Slider;
