import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
    setGeneralDetails,
    setNewsDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/new_header.jpg";
import {Link} from "react-router-dom";
import DataLoader from "../../common/dataLoader/dataLoader";
import PageName from "../../common/pagename/pagename";

function News(props) {
    const [loading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState(props.newsList);

    const getData = async () => {
        await axios
            .get(`${serverLink}news/list/active`)
            .then((result) => {
                const data = result.data;
                setNewsList(data);
                props.setOnNewsDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    useEffect(() => {
        getData();
    }, [""]);

    return (
        <>
            {loading ? (
                <DataLoader />
            ) : (
                <>
                    <PageName page={"News"} />
                    <Banner
                        Image={bg3}
                        Title={"News"}
                        breadcrumb={["Home", "News"]}
                    />

                    <section className="mb-5"/>
                    <section className="space-ptb position-relative">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-10 text-center">
                                    <div className="section-title">
                                        <h2 className="title">Latest News</h2>
                                        <p className="mb-0">Check out our latest blog posts, articles, and events</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    newsList.length > 0 ?
                                        newsList.map((news, index)=>{
                                            return(
                                                <div key={index} className="col-lg-4 col-md-6 mb-4">
                                                    <div className="blog">
                                                        <div className="blog-image">
                                                            <Link to={`/resources/news/${news.Slug}`}><img className="img-fluid img-thumbnail border-radius w-100" style={{maxHeight: "200px", minHeight: "200px"}} src={`${serverLink}public/uploads/news_uploads/${news.ImagePath}`} alt=""/></Link>
                                                        </div>
                                                        <div className="blog-content pt-4">
                                                            <h6 className="blog-title mb-3"><Link to={`/resources/news/${news.Slug}`}>{news.Title?.slice(0, 75) + (news.Title?.length > 75 ? "..." : "")}</Link></h6>
                                                            <div className="blog-meta mb-0">
                                                                <ul className="list-unstyled mb-0 d-flex">
                                                                    <li className="mr-3"><a href="#"><i
                                                                        className="fas fa-calendar-alt pr-1"/>{formatDateAndTime(news.InsertedDate, "date")}</a></li>
                                                                    <li><a href="#"><i className="fas fa-user pr-1"/> {news.InsertedBy?.slice(0, 18) + (news.InsertedBy?.length > 18 ? "..." : "")}</a></li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="alert alert-info text-center">No News Found</div>
                                }
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(News);