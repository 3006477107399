import React from "react";

function LearningCentersSection() {
    return (
        <section className="space-ptb bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="mb-5">Kadpoly ODFeL Centres</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-md-4 mb-3 mb-xl-0">
                        <div className="category text-center">
                            <div className="category-item bg-dark border-radius py-4">
                                <div className="category-icon">
                                    <i className="flaticon-global fa-3x text-white"></i>
                                </div>
                                <h5 className="mt-3">Main Campus</h5>
                                <p className="text-white">Kaduna Polytechnic Main Campus, Tudun Wada, Kaduna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-3">
                        <div className="category text-center">
                            <div className="category-item bg-danger border-radius py-4">
                                <div className="category-icon">
                                    <i className="flaticon-politics fa-3x text-white"></i>
                                </div>
                                <h5 className="mt-3 text-white">Barnawa Centre</h5>
                                <p  className="text-white">College of Environmental Studies. Kaduna Polytechnic, Barnawa, Kaduna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-3">
                        <div className="category text-center">
                            <div  className="category-item py-4 bg-secondary border-radius">
                                <div className="category-icon">
                                    <i className="flaticon-stock-market fa-3x text-white"></i>
                                </div>
                                <h5 className="mt-3 text-white">Ungwa Rimi Centre</h5>
                                <p  className="text-white">College of Business & Mgt. Studies. Kaduna Polytechnic, U/Rimi, Kaduna</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="row justify-content-center">*/}
                {/*  <a className="btn btn-dark mr-3 mt-5" href="#">View All Categories</a>*/}
                {/*</div>*/}
            </div>
        </section>
    )
}

export default LearningCentersSection;
