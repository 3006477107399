import axios from "axios";
import React, { useEffect, useState } from "react";
import {currencyConverter, formatDateAndTime, serverLink} from "../../resources/url";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";
import DataLoader from "../common/dataLoader/dataLoader";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import PageName from "../common/pagename/pagename";

const ExamVerification = (props)=>{
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(true);
    const [courses, setCourses] = useState([]);
    const [managementFee, setManagementFee] = useState([]);
    const [personalData, setPersonalData] = useState([]);
    let query = useQuery();
    let session = query.get("x");
    let semester = query.get("y");
    let student = query.get("z");
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const getData = async () => {
        await axios
            .get(`${serverLink}general/exam-verification/${session}/${semester}/${student}`)
            .then((result) => {
                if (result.data.Personal.length > 0){
                    let courses = [...result.data.Course, ...result.data.CourseReseat];
                    setCourses(courses);
                    setPersonalData(result.data.Personal);
                    setManagementFee(result.data.Management);
                }else{
                    setShow(false)
                }
                setLoading(false);
            })
            .catch((error) => {
                setShow(false)
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);

    return loading ? (
        <section className="space-ptb position-relative">
            <div className="container" style={{height: '400px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
        </section>
    ) : (
        <>
            <PageName page="Exam Verification" />
            <Banner
                Image={require("../../images/verification.jpg")}
                Title={"Exam Verification"}
                breadcrumb={["Examination", "Verification", ""]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="" style={{marginLeft:"20px", marginRight:"20px"}}>
                    {
                        show ?
                            <div className="row">
                                <div className="col-lg-9 mb-5 mb-lg-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="shadow border-radius p-4">
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <img className="img-fluid border-radius img-thumbnail w-100 mb-3" style={{maxHeight: '380px'}} src={personalData.length > 0 ? `https://kadpolyodfel.ng/assets/passport/${personalData[0].PICTURE}` : ""} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="">
                                                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                                        <div className="section-title mb-2">
                                                                            <h3 className="title">Student Details</h3>
                                                                            <hr style={{marginTop: '0px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                        </div>
                                                                        {
                                                                            personalData.length > 0 ?
                                                                                <div className="section-title mb-2">


                                                                                    <div className="table-responsive-md ">
                                                                                        <table className="table table-responsive-md">
                                                                                            <tr>
                                                                                                <th width={200}>Student Name:</th>
                                                                                                <td>{personalData[0].StudentName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th width={200}>Registration No.:</th>
                                                                                                <td>{personalData[0].STUDENTID}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Programme:</th>
                                                                                                <td>{personalData[0].Programme}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Level:</th>
                                                                                                <td>{personalData[0].STUDENT_LEVEL}00 Level</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Semester:</th>
                                                                                                <td>{atob(semester)?.toString() === "1" ? "First" : "Second" }</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Session:</th>
                                                                                                <td>{atob(session)?.toString()}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                    <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                                </div>


                                                                                :
                                                                                <div className="alert alert-danger text-center">No Record Found</div>

                                                                        }
                                                                    </div>

                                                                    <div className="mb-2" id="nav-contact">
                                                                        <div className="section-title mb-3">
                                                                            <h3 className="title">Management Fee</h3>
                                                                            <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                        </div>
                                                                        {
                                                                            managementFee.length > 0 ?
                                                                                <table className="table table-responsive-md table-striped table-hover w-100">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>S/N</th>
                                                                                        <th>Transaction ID</th>
                                                                                        <th>Payment Type</th>
                                                                                        <th>Amount Paid</th>
                                                                                        <th>Session</th>
                                                                                        <th>Payment Date</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                        managementFee.length > 0 ?
                                                                                            managementFee.map((item, i)=> {
                                                                                                return (
                                                                                                    <tr key={i}>
                                                                                                        <td>{i+1}</td>
                                                                                                        <td>{item.TRANSACTION_ID}</td>
                                                                                                        <td>{item.PAYMENT_TYPE}</td>
                                                                                                        <td>{currencyConverter(item.AMOUNT)}</td>
                                                                                                        <td>{item.SESSION_CODE}</td>
                                                                                                        <td>{formatDateAndTime(item.DATE_PAID, 'date')}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <tr>
                                                                                                <td colSpan={4}><div className="alert alert-danger text-center">Management Fee payment not found</div></td>
                                                                                            </tr>

                                                                                    }
                                                                                    </tbody>
                                                                                </table>

                                                                                :
                                                                                <div className="alert alert-danger text-center">Management Fee payment not found</div>
                                                                        }
                                                                    </div>
                                                                    <hr style={{marginTop: '0px', marginBottom: '10px', backgroundColor: '#cccccc'}} />
                                                                    <div className="" id="nav-contact">
                                                                        <div className="section-title mb-3">
                                                                            <h3 className="title">Registered Courses</h3>
                                                                            <hr style={{marginTop: '0px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                        </div>
                                                                        {
                                                                            courses.length > 0 ?
                                                                                <table className="table table-responsive-md table-striped table-hover w-100">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>S/N</th>
                                                                                        <th>Course Code</th>
                                                                                        <th>Course Title</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                        courses.length > 0 ?
                                                                                            courses.map((staff, i)=> {
                                                                                                return (
                                                                                                    <tr key={i}>
                                                                                                        <td>{i+1}</td>
                                                                                                        <td>{staff.COURSE_CODE}</td>
                                                                                                        <td>{staff.CourseName}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <tr>
                                                                                                <td colSpan={4}><div className="alert alert-danger text-center">No Registered Course</div></td>
                                                                                            </tr>

                                                                                    }
                                                                                    </tbody>
                                                                                </table>

                                                                                :
                                                                                <div className="alert alert-danger text-center">No Registered Course</div>
                                                                        }
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="row justify-content-center">
                                <div className="col-lg-12 mb-2">
                                    <div className="  shadow p-5 ">
                                        <div className="col-md-12  alert alert-danger">
                                            <h4 className="text-center text-danger"><b>Invalid!</b></h4>
                                            <p className="text-center"><b>Sorry, the system could not find the student record!</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </section>

        </>
    )
}

export default ExamVerification;
