import React, { useEffect, useState } from "react";
import {
    setCourseListDetails,
    setDepartmentListDetails,
    setFacultyListDetails, setGalleryDetails,
    setHomeSliderDetails,
    setNewsDetails, setProgrammeDetails, setSchoolsDetails, setStaffListDetails,
} from "./actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "./resources/url";
import PageRoutes from "./component/pageroutes/pageroutes";
import DataLoader from "./component/common/dataLoader/dataLoader";
import {browserName, isMobile} from "react-device-detect";

function App(props) {
  const [isLoading, setIsLoading] = useState(
      !(!(props.facultyList.length > 0 && props.staffList.length > 0 && props.departmentList.length > 0 &&
          props.courseList.length > 0 && props.programmeList > 0 && props.newsList > 0 && props.schoolsList > 0 && props.sliderList > 0))
  );

    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then(async (result)=> {
            await getVisitorData(result.data);
        }).catch((e)=>{
            console.log("Error Getting User Details")
        })

    }

    const getVisitorData = async (locData) => {
        let sendData = {
            IPAddress: locData.IPv4,
            Country: locData.country_name,
            lat: locData.latitude,
            lng: locData.longitude,
            IsMobileRequest: isMobile ? "1" : "0",
            UserAgent: browserName,
        }
        await axios.post(`${serverLink}general/website/visit-hit`, sendData).then(data => {

        }).catch(err => {
            console.log("Error Adding User Details")
        });
    };

    const getData = async () => {
    await axios
        .get(`${serverLink}general/website/content`)
        .then((result) => {
            props.setOnFacultyListDetails(result.data.College);
            props.setOnDepartmentListDetails(result.data.Department);
            props.setOnCourseListDetails(result.data.Course);
            props.setOnNewsDetails(result.data.News);
            props.setOnSliderDetails(result.data.Slider)
            props.setOnStaffListDetails(result.data.Staff)
            props.setOnSchoolDetails(result.data.School)
            props.setOnProgrammesDetails(result.data.Programme)
            props.setOnGalleryDetails(result.data.Gallery)
           setIsLoading(false);
        }
        )
        .catch((error) => {
           setIsLoading(false);
          console.log("error", error);
        });
  };

    useEffect(() => {
      getData();
      getLocationData();
    }, [""]);

  return (

      <div className="App">{isLoading ? <DataLoader /> : <PageRoutes />}</div>
  );
}
const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
    courseList: state.CourseListDetails,
    departmentList: state.DepartmentListDetails,
    staffList: state.StaffListDetails,
    newsList: state.NewsDetails,
    sliderList: state.SliderDetails,
    schoolsList: state.SchoolsDetails,
    programmeList: state.ProgrammeDetails,
    galleryList: state.ProgrammeDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
    setOnDepartmentListDetails: (p) => {
      dispatch(setDepartmentListDetails(p));
    },
    setOnCourseListDetails: (p) => {
      dispatch(setCourseListDetails(p));
    },
      setOnStaffListDetails: (p) => {
      dispatch(setStaffListDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
      setOnProgrammesDetails: (p) => {
      dispatch(setProgrammeDetails(p));
    },
    setOnSliderDetails: (p) => {
      dispatch(setHomeSliderDetails(p));
    },
    setOnSchoolDetails: (p) => {
      dispatch(setSchoolsDetails(p));
    },
    setOnGalleryDetails: (p) => {
      dispatch(setGalleryDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);