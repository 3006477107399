import React, {useState} from "react";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {Link} from "react-router-dom";
import swal from "sweetalert";
const CryptoJS = require("crypto-js");


function StudentLogin(props) {
    const [IsLoading, setIsLoading] = useState(false);
    const [IsNotValid, setIsNotValid] = useState(false);
    const [formData, setFormData] = useState({
        EmailAddress: "",
        Password: "",
        StudentName: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.EmailAddress.toString().trim() === "") {
            showAlert("Empty Field", "Please enter your email address", "error");
            return false;
        }

        if(formData.Password.toString().trim() === "") {
            showAlert("Empty Field", "Please enter your password", "error");
            return false;
        }

        const sendData = {
            ...formData
        }

        toast.info("Login.... Please wait");
        setIsLoading(true)
        axios.post(`${serverLink}login/student-login`, sendData)
            .then(result => {
                const response = result.data.message;
                const userData = result.data.userData;

                if (response === 'invalid_login') {
                    showAlert("INVALID LOGIN CREDENTIALS", "Invalid email or password. Kindly check your credential and try again", "error");
                } else if (response === 'not valid') {
                    setIsNotValid(true)
                    setFormData({
                        ...formData,
                        StudentName: userData.student_name
                    })
                    // showAlert(
                    //     "ERROR",
                    //     `Dear ${userData.student_name}, \n  please endeavour to complete your application by clicking the link below before proceeding to the student dashboard. \n` + '<a href="https://kadpolyodfel.ng/application/applicant_login.php"  target="_blank" className="btn btn-primary btn-sm">Application Portal</a>',
                    //     "warning"
                    // );
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else if (response === 'password') {
                    showAlert("INVALID LOGIN CREDENTIALS", "Something went wrong, kindly change your password", "error");
                } else {

                    let data = btoa(JSON.stringify(userData));
                    toast.success("Login Successful. Please wait while the system is redirecting ...");

                    setTimeout(function () {
                        // console.log(data)
                        sessionStorage.setItem('token', data);
                        window.location.href = `https://student.kadpolyodfel.ng/parameters.php?token=${data}`
                    }, 2000)
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    return (
        <>
            <PageName page={"Student Login"} />
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                                <h2>Login To Student Portal</h2>
                                <p>Sign in with your email and password</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2">
                            {
                                IsNotValid ?
                                    <form className="login bg-white shadow p-5">
                                        <div className="form-group">
                                            Dear <b>{formData.StudentName}</b>, <br/>  please endeavour to complete your application by clicking the link below before proceeding to the student dashboard. <br/>
                                            <a href="https://application.kadpolyodfel.ng/applicant_login.php"  target="_blank" className="btn btn-primary btn-sm mt-3">Application Portal</a>
                                        </div>
                                        <p className="mt-4 mb-0">Completed your application? <a  onClick={()=> setIsNotValid(false)}> Login</a></p>
                                    </form>
                                    :
                                    <form className="login bg-white shadow p-5">
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input type="email" className="form-control" id="EmailAddress" name="EmailAddress"   onChange={handleChange} placeholder="Enter email"/>
                                            <span className="focus-border"/>
                                        </div>
                                        <div className="form-group">
                                            <div className="mt-4">Password <p className="mb-1 float-right"> <Link to="/portal/forgotten-password" > Forgot Password?</Link></p></div>
                                            <input type="password" className="form-control" id="Password"  name="Password"   onChange={handleChange} placeholder="Password" />
                                            <span className="focus-border"/>
                                        </div>
                                        <button type="submit" className="btn btn-dark mt-4 w-100"  onClick={handleSubmit}>LOGIN</button>
                                        <p className="mt-4 mb-0">Not yet a student? <a href="https://application.kadpolyodfel.ng/apply_now.php" target="_blank"> Apply here</a></p>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(StudentLogin);