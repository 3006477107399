import React from "react";
import {Link} from "react-router-dom";

const Banner =(props)=>{


    return(
        <section className="inner-banner bg-overlay-black-70"  style={{backgroundImage: `url("${props.Image}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height:`${props.height}`}}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="text-white">{props.Title}</h2>
                </div>  
                <div className="d-flex justify-content-center ">
                  <ol className="breadcrumb mb-2 p-0">
                    <li className="breadcrumb-item"><Link to="/">{props.breadcrumb[0]}</Link></li>
                    <li className="breadcrumb-item active"><span>{props.breadcrumb[1]}</span></li>
                      {
                          !props.breadcrumb[2] ? "" :  <li className="breadcrumb-item active"><span>{!props.breadcrumb[2] ? "" : props.breadcrumb[2]}</span></li>
                      }

                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default Banner;