import React from "react";

function CounterSection(props) {
    return (
        <section className="py-3" style={{backgroundColor: '#FFFFFF !important', color: '#222222'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="d-flex align-items-center">
                            <i className="flaticon-mortarboard-2 fa-4x text-black"></i>
                            <h5 className="pl-4 font-weight-normal mb-0 text-black-50"> {props.collegeList?.length} Colleges</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex align-items-center">
                            <i className="flaticon-knowledge fa-4x text-black"></i>
                            <h5 className="pl-4 font-weight-normal mb-0 text-black-50">{props.departmentList?.length} courses</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex align-items-center">
                            <i className="flaticon-medal fa-4x text-black"></i>
                            <h5 className="pl-4 font-weight-normal mb-0 text-black-50">NBTE APPROVED</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CounterSection;
