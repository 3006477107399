import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
    setFacultyListDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/college.jpg";
import {Link} from "react-router-dom";
import DataLoader from "../../common/dataLoader/dataLoader";
import PageName from "../../common/pagename/pagename";

function Colleges(props) {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [collegeList, setCollegeList] = useState(props.collegeList);

    const getData = async () => {
        await axios
            .get(`${serverLink}general/colleges/list`)
            .then((result) => {
                const data = result.data;
                setCollegeList(data);
                props.setOnFacultyDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    useEffect(() => {
        getData();
    }, [""]);

    return (
        <>
            {loading ? (
                <DataLoader />
            ) : (
                <>
                    <PageName page={"Colleges"} />
                    <Banner
                        Image={bg3}
                        Title={"Our Colleges"}
                        breadcrumb={["Home", "Colleges"]}
                    />


                    <section className="mb-5"/>
                    <section className="space-ptb position-relative">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-10 text-center">
                                    <div className="section-title">
                                        <h2 className="title">Our Colleges</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row staff-sidebar ">
                                {
                                    collegeList.length > 0 ?
                                        collegeList.map((college, index)=>{
                                            return(
                                                <Link to={`/academics/college/${college.Slug}`} key={index} className="col-xl-6 col-md-4  mb-3">
                                                    <div className="category text-center">
                                                        <div className="category-item  border-radius py-4" style={{backgroundColor: '#cccccc'}}>
                                                            <div className="">
                                                                <i className="flaticon-mortarboard-2 fa-4x text-dark"></i>
                                                            </div>
                                                            <h5 className="mt-3">{"College of "+ college.COLLEGE}</h5>
                                                            <p className="text-dark"> <i className="fas fa-location-arrow"></i> {college.COL_LOCATION}, Kaduna</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                        :
                                        <div className="alert alert-info text-center col-md-12">No College Found</div>
                                }
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        collegeList: state.FacultyListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Colleges);