import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {useNavigate, useParams} from "react-router-dom";
const CryptoJS = require("crypto-js");


function ResetPassword(props) {
    const navigate = useNavigate();
    const {slug} = useParams();
    const [IsLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        TOKEN: atob(slug),
        PASSWORD: "",
        CONFIRM_PASSWORD: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.PASSWORD.toString().trim() === "") {
            showAlert("Empty Field", "Please enter new password", "error");
            return false;
        }

        if(formData.CONFIRM_PASSWORD.toString().trim() === "") {
            showAlert("Empty Field", "Please enter confirm password", "error");
            return false;
        }

        if(formData.PASSWORD.toString() !== formData.CONFIRM_PASSWORD.toString()) {
            showAlert("PASSWORD NOT MATCH", "Your password did not match. Please try again!", "error");
            return false;
        }

        if(formData.CONFIRM_PASSWORD.toString().trim().length < 7) {
            showAlert("Password length", "Password length cannot be less than 8 characters  ", "error");
            return false;
        }

        const sendData = {
            ...formData
        }

        toast.info("Resetting your password, please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}login/student/reset-password`, sendData)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    toast.success("Your account password reset was successful. Please wait while the system is redirecting ...");
                    setTimeout(function () {
                        navigate("/portal/student-login");
                    }, 2000)
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    showAlert("INVALID TOKEN", "Something went wrong resetting your password", "error");
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    useEffect(()=> {
        if (!slug){
            navigate("/");
        }
    }, [])

    return (
        <>
            <PageName page={"Student Login"} />
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                                <h2>Reset Password</h2>
                                <p>Use the form below to reset your password</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2">
                            <form className="login bg-white shadow p-5">
                                <div className="form-group">
                                    <label>New Password </label>
                                    <input type="password" className="form-control" id="PASSWORD"  name="PASSWORD"   onChange={handleChange} placeholder="Password" />
                                    <span className="focus-border"/>
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password </label>
                                    <input type="password" className="form-control" id="CONFIRM_PASSWORD"  name="CONFIRM_PASSWORD"   onChange={handleChange} placeholder="Confirm Password" />
                                    <span className="focus-border"/>
                                </div>
                                {
                                    IsLoading ?
                                        <button type="submit" className="btn btn-dark mt-4 w-100" disabled={IsLoading}>Please Wait...</button>
                                        :
                                        <button type="submit" className="btn btn-dark mt-4 w-100"  onClick={handleSubmit}>RESET</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
const mapStateToProps = (state) => {
    return {
        ApplicantData: state.GeneralDetails,
    };
};

export default connect(mapStateToProps, null)(ResetPassword);