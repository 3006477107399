import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setFacultyListDetails} from "../../../actions/setactiondetails";
import {currencyConverter, serverLink} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/03.jpg";
import * as DOMPurify from 'dompurify';
import DataLoader from "../../common/dataLoader/dataLoader";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import CollegeSide from "./college-side";
import PageName from "../../common/pagename/pagename";

const CollegeDetails = (props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const  slug  = useParams();

    if (slug.slug === "") navigate("/academics/colleges");

    const [loading, setLoading] = useState(props.collegeList.filter((item) => item.Slug === slug.slug).length < 1 );
    let collegeData = props.collegeList.filter((item) => item.Slug === slug.slug);
    let college_code = collegeData.length > 0 ? collegeData[0].COL_CODE : "";
    const [search, setSearch] = useState("");
    const [staffList, setStaffList] = useState(props.staffList.filter((item) => item.CollegeCode === college_code));
    const [courses, setCourses] = useState(props.courseList.filter((item) => item.CollegeCode === college_code));
    const [collegeDetail, setCollegeDetail] = useState(
        collegeData.length > 0
            ? props.collegeList.filter(
                (item) => item.COL_CODE === college_code
            )
            : []
    );


    const getData = async () => {
        await axios
            .get(`${serverLink}general/college/content`)
            .then((result) => {
                const colData = result.data.College.filter((item) => item.Slug === slug.slug);
                if (colData.length > 0){
                    let colCode = colData[0].COL_CODE;
                    const staffData = result.data.Staff.filter((item) => item.CollegeCode === colCode);
                    const courseData = result.data.Department.filter((item) => item.CollegeCode === colCode);
                    setCourses(courseData);
                    setCollegeDetail(colData);
                    setStaffList(staffData);
                    props.setOnCollegeDetails(result.data.College);
                }else{
                    navigate("/academics/colleges");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);

    return loading ? (
        <section className="space-ptb position-relative">
            <div className="container" style={{height: '400px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
        </section>
    ) : (
        <>
            <PageName page={collegeDetail.length > 0 ? "College of "+ collegeDetail[0].COLLEGE : slug} />
            <Banner
                Image={collegeDetail.length > 0 ? `${serverLink}public/uploads/college_uploads/${collegeDetail[0].Image}` : require("../../../images/blog/03.jpg")}
                Title={collegeDetail.length > 0 ? "College of "+ collegeDetail[0].COLLEGE : slug}
                breadcrumb={["Academics", "College", collegeDetail.length > 0 ? collegeDetail[0].COLLEGE : slug]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="" style={{marginLeft:"20px", marginRight:"20px"}}>
                    <div className="row">
                        <div className="col-lg-9 mb-5 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="shadow border-radius p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '380px'}} src={collegeDetail.length > 0 ? `${serverLink}public/uploads/college_uploads/${collegeDetail[0].Image}` : require("../../../images/blog/03.jpg")} alt=""/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="">
                                                            <nav className="mt-2">
                                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                                    <a className="nav-item nav-link active col-sm-6" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" style={{border: '1px solid #218F76'}}>College Details</a>
                                                                    <a className="nav-item nav-link col-sm-6" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" style={{border: '1px solid #218F76'}}>Staff</a>
                                                                </div>
                                                                <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                            </nav>
                                                            <div className="tab-content bg-white " id="nav-tabContent">
                                                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                                    <div className="section-title mb-2">
                                                                        <h3 className="title">College Courses</h3>
                                                                        <hr style={{marginTop: '0px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                    </div>
                                                                    {
                                                                        courses.length > 0 ?
                                                                            courses.map((item, index)=> {
                                                                                return (
                                                                                    <div key={index} className="section-title mb-2">
                                                                                        <h5 className="title text-secondary">{item.DEPARTMENT}</h5>
                                                                                        <div  className="mb-3">
                                                                                            <b>Course Details </b><br/>
                                                                                        </div>
                                                                                        <div className="table-responsive-md">
                                                                                            <table className="table table-responsive">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th width={200}>COURSE:</th>
                                                                                                        <td>{item.DEPARTMENT}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>Location:</th>
                                                                                                        <td>{item.LOCATION}</td>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                            </table>
                                                                                        </div>
                                                                                        <Link to={`/admission/course/${item.Slug}`} className="btn btn-primary mb-3 mt-1">More Details</Link>
                                                                                        <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className="alert alert-info text-center">No Course Found</div>

                                                                    }
                                                                </div>
                                                                <div className="tab-pane fade" id="nav-contact" role="tabpanel"  aria-labelledby="nav-contact-tab">
                                                                    <div className="section-title mb-3">
                                                                        <h3 className="title">Staff</h3>
                                                                    </div>
                                                                    {
                                                                        staffList.length > 0 ?
                                                                            <table className="table table-responsive-md table-striped table-hover w-100">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>Staff Name</th>
                                                                                    <th>Department</th>
                                                                                    <th>Designation</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {
                                                                                    staffList.length > 0 ?
                                                                                        staffList.map((staff, i)=> {
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <td><Link to={`/academics/staff/${staff.Slug}`} className="">{staff.StaffName}</Link></td>
                                                                                                    <td>{staff.DepartmentName}</td>
                                                                                                    <td>{staff.Designation}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        <tr>
                                                                                            <td colSpan={4}><div className="alert alert-info text-center">No Staff Found</div></td>
                                                                                        </tr>

                                                                                }
                                                                                </tbody>
                                                                            </table>

                                                                            :
                                                                            <div className="alert alert-info text-center">No Staff Found</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <CollegeSide/>

                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        collegeList: state.FacultyListDetails,
        courseList: state.DepartmentListDetails,
        staffList: state.StaffListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnCollegeDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CollegeDetails);
