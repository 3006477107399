import React from "react";
import {connect} from "react-redux";
import {formatDateAndTime, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";


function LatestNews(props) {
    return (
        <section className="space-ptb " style={{backgroundImage: `url(${require('../../../images/bg/bg.png')})`, backgroundSize: 'cover'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="bg-white border-radius">
                            {/*<div className="bg-primary rounded-top p-3">*/}
                            {/*    <h3>Apply Here</h3>*/}
                            {/*    <p className="text-white mb-0">Start your application with ease.</p>*/}
                            {/*</div>*/}
                            <form className="form-row mt-0 align-items-center p-4 p-lg-4 " style={{marginTop: ''}}>
                                <div className="col-lg-12 text-center align-items-center">
                                    <div className="">
                                        <img className="img-fluid border-radius" src={require('../../../images/ajoge.jpg')} alt=""/>
                                    </div>
                                </div>
                                <div className=" col-md-12 review-body mt-2">
                                    <h5 className="mb-2 mt-2"><a href="#">Dr. Naseer Sanni Ajoge</a></h5>
                                    <span className="">Dir. Open, Distance & Flexible Learning (ODFeL)</span>
                                    <br/>
                                    <span className="">Kaduna Polytechnic</span>
                                    <hr/>
                                </div>
                            </form>
                        </div>
                        {/*<div className="col-lg-12 text-center align-items-center">*/}
                        {/*    <div className="registration d-none d-lg-block">*/}
                        {/*        <img className="img-fluid border-radius" src={require('../../../images/ajoge.jpg')} alt=""/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <div className="row mb-4 mb-md-0">
                            <div className="col-md-8">
                                <div className="section-title">
                                    <h2 style={{color: '#555353'}}>Latest News</h2>
                                </div>
                            </div>
                            <div className="col-md-4 text-md-right">
                                <Link className="btn btn-white" to="/resources/news">View All</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    props.newsList?.length > 0 ?
                                        props.newsList.slice(0,2).map((item,index)=>{
                                            return(
                                                <div key={index} className="event-list bg-white border-radius mb-3 p-3">
                                                    <div className="d-sm-flex">
                                                        <div className="event-img">
                                                            <img className="w-100 h-100 img-fluid img-thumbnail border-radius" src={`${serverLink}public/uploads/news_uploads/${item.ImagePath}`} alt=""/>
                                                        </div>
                                                        <div className="event-content pl-sm-4 pt-4 p-sm-0">
                                                            <Link to={`/resources/news/${item.Slug}`} className="h6" style={{color: '#222222'}}>{item.Title?.slice(0, 75) + (item.Title?.length > 75 ? "..." : "")}</Link>
                                                            <div className="event-meta my-3">
                                                                <ul className="list-unstyled mb-0 d-flex">
                                                                    <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(item.InsertedDate, 'date')}</a></li>
                                                                    <li><a href="#"><i className="fas fa-user pr-1"></i>{item.InsertedBy?.slice(0, 20) + (item.InsertedBy?.length > 20 ? "..." : "")}</a></li>
                                                                </ul>
                                                            </div>
                                                            <p className="text-secondary" dangerouslySetInnerHTML={{__html: item.Description?.slice(0, 100) + (item.Description?.length > 100 ? "..." : "")}}></p>
                                                            <Link className="btn btn-sm btn-dark btn-round" to={`/resources/news/${item.Slug}`}>Read More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <h2 className="text-center alert alert-info">No News Available</h2>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LatestNews;
