import React from "react";
import {serverLink} from "../../../resources/url";

function GallerySection(props) {
    return (
        <section className="space-ptb">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 text-center">
                        <div className="section-title">
                            <h2>Our Gallery</h2>
                            <small>Some Activities From Our Campus</small>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="row my-shuffle-container columns-3 popup-gallery full-screen">
                            {
                                props.galleryList.slice(0,6).length > 0 && props.galleryList.slice(0,6).map((item, index)=> {
                                    return (
                                        <div key={index} className="grid-item col-6" data-groups={`["${item.CategoryName}"]`}>
                                            <div className="portfolio-item">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={`${serverLink}public/uploads/gallery_uploads/${item.ImagePath}`} alt="" style={{width: '100%', minHeight: '100%', display: 'block', objectFit: 'cover'}}/>
                                                    <div className="portfolio-overlay">
                                                        <a className="portfolio-img" href={`${serverLink}public/uploads/gallery_uploads/${item.ImagePath}`}><i className="fas fa-arrows-alt"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="row justify-content-center">
                            <a className="btn btn-dark mr-3 mt-5" href="/resources/gallery">View All Galleries</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GallerySection;
