import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import { setStaffListDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/staff.jpg";
import {Link} from "react-router-dom";
import DataLoader from "../../common/dataLoader/dataLoader";
import PageName from "../../common/pagename/pagename";

function Staff(props) {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [staffList, setStaffList] = useState(props.staffList);

    const getData = async () => {
        await axios
            .get(`${serverLink}staff/website/list/active`)
            .then((result) => {
                const data = result.data;
                setStaffList(data);
                props.setOnStaffDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    useEffect(() => {
        setStaffList(
            props.staffList.filter((e) =>
                e.StaffName.toLowerCase().includes(search.toLowerCase()) ||
                e.Designation.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search]);

    const onStaffChange = (e) => {
        const selectedStaff = e.target.value;
        setSearch(selectedStaff);
    };

    useEffect(() => {
        getData();
    }, [""]);

    return (
        <>
            {loading ? (
                <DataLoader />
            ) : (
                <>
                    <PageName page={"Staff"} />
                    <Banner
                        Image={bg3}
                        Title={"Staff Directorate"}
                        breadcrumb={["Home", "Staff"]}
                    />

                    <section className="mb-5"/>
                    <section className="space-ptb position-relative">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-10 text-center">
                                    <div className="section-title">
                                        <h2 className="title">Our Staff</h2>
                                        <p className="mb-0">Meet our prestigious staff</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row staff-sidebar ">
                                <div className="widget col-md-12">
                                    <div className="widget-title">
                                        <h5>Search Staff</h5>
                                    </div>
                                    <form className="navbar-form search d-flex">
                                        <input type="text" name="q" className="form-control input-search rounded-0 bg-white" placeholder="Search" onChange={onStaffChange} autoComplete="off" />
                                        <a><i className="fa fa-search text-white" /></a>
                                    </form>
                                </div>
                                {
                                    staffList.length > 0 ?
                                        staffList.map((staff, index)=>{
                                            return(
                                                <div  key={index}  className="col-xl-3 col-lg-6 col-sm-6 mb-4">
                                                    <div className="team">
                                                        <div className="team-img">
                                                            <Link to={`/academics/staff/${staff.Slug}`} className="team-name"> <img className="img-fluid img-thumbnail border-radius w-100" style={{maxHeight: "320px", minHeight: "320px"}} src={!staff.Image ? staff.Gender === "Male" ? require('../../../images/male.jpg')  : require('../../../images/female.jpg') : `${serverLink}public/uploads/staff_uploads/${staff.Image}`} alt=""/></Link>
                                                        </div>
                                                        <div className="team-info">
                                                            <Link to={`/academics/staff/${staff.Slug}`} className="team-name">{staff.StaffName}</Link>
                                                            <p className="team-leader">{staff.Designation}</p>
                                                            <ul className="list-unstyled">
                                                                <li><a href={staff.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                                <li><a href={staff.Twitter} target="_blank"><i className="fab fa-twitter"></i></a> </li>
                                                                <li><a href={staff.Linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                                                <li><a href={staff.Scholar} target="_blank"><i className="fab fa-google"></i></a> </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                        })
                                        :
                                        <div className="alert alert-info text-center col-md-12">No Staff Found</div>
                                }
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        staffList: state.StaffListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnStaffDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Staff);