import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
    setGalleryDetails,
    setGeneralDetails,
    setNewsDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/gallery-banner.png";
import PageName from "../../common/pagename/pagename";

function Gallery(props) {
    const [loading, setLoading] = useState(false);
    const [galleryList, setGalleryList] = useState(props.galleryData)
    const [categoryList, setCategoryList] = useState([])

    const getData = async () => {
        await axios
            .get(`${serverLink}gallery/list`)
            .then((result) => {
                setGalleryList(result.data.Gallery);
                setCategoryList(result.data.Category);
                props.setOnGalleryDetails(result.data.Gallery);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <PageName page={"Gallery"} />
                    <Banner
                        Image={bg3}
                        Title={"Gallery"}
                        breadcrumb={["Home", "Gallery"]}
                    />

                    <section className="mb-5"/>
                    <section className="space-ptb">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-10 text-center">
                                    <div className="section-title">
                                        <h2>Our Gallery</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="filters-group mb-lg-4 text-center">
                                        <button className="btn-filter  active" data-group="all">All</button>
                                        {
                                            categoryList.length > 0 && categoryList.map((item, index)=> {
                                                return (
                                                    <button key={index} className="btn-filter" data-group={item.CategoryName}>{item.CategoryName}</button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row my-shuffle-container columns-3 popup-gallery full-screen">
                                        {
                                            galleryList.length > 0 && galleryList.map((item, index)=> {
                                                return (
                                                    <div key={index} className="grid-item" data-groups={`["${item.CategoryName}"]`}>
                                                        <div className="portfolio-item">
                                                            <div className="position-relative">
                                                                <img className="img-fluid" src={`${serverLink}public/uploads/gallery_uploads/${item.ImagePath}`} alt="" style={{minHeight: '300px'}}/>
                                                                <div className="portfolio-overlay">
                                                                    <a className="portfolio-img" href={`${serverLink}public/uploads/gallery_uploads/${item.ImagePath}`}><i
                                                                        className="fas fa-arrows-alt"/></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        galleryData: state.GalleryDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGalleryDetails: (p) => {
            dispatch(setGalleryDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);