import storage from "redux-persist/lib/storage";
import {
  CourseListDetailsReducer,
  DepartmentListDetailsReducer,
  FacultyListDetailsReducer,
  NewsDetailsReducer,
  StaffListDetailsReducer,
  GeneralDetailsReducer,
  HomepageContentDetailsReducer,
  ProgrammeDetailsReducer,
  SliderDetailsReducer,
  SchoolsDetailsReducer,
  GalleryDetailsReducer,
} from "./detailsReducer";
import { projectCode } from "../resources/url";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  FacultyListDetails: FacultyListDetailsReducer,
  DepartmentListDetails: DepartmentListDetailsReducer,
  CourseListDetails: CourseListDetailsReducer,
  StaffListDetails: StaffListDetailsReducer,
  NewsDetails: NewsDetailsReducer,
  GeneralDetails: GeneralDetailsReducer,
  HomepageContentDetails: HomepageContentDetailsReducer,
  ProgrammeDetails: ProgrammeDetailsReducer,
  SliderDetails: SliderDetailsReducer,
  SchoolsDetails: SchoolsDetailsReducer,
  GalleryDetails: GalleryDetailsReducer,
});

const persistConfig = {
  key: projectCode,
  storage,
};

export default persistReducer(persistConfig, rootReducer);
