import { projectCode, serverLink } from "../resources/url";
import axios from "axios";


export const setFacultyListDetails = (p) => {
  return {
    type: `SET_${projectCode}_FACULTY_LIST_DETAILS`,
    payload: p,
  };
};

export const setDepartmentListDetails = (p) => {
  return {
    type: `SET_${projectCode}_DEPARTMENT_LIST_DETAILS`,
    payload: p,
  };
};

export const setCourseListDetails = (p) => {
  return {
    type: `SET_${projectCode}_COURSE_LIST_DETAILS`,
    payload: p,
  };
};

export const setStaffListDetails = (p) => {
  return {
    type: `SET_${projectCode}_STAFF_LIST_DETAILS`,
    payload: p,
  };
};

export const setNewsDetails = (p) => {
  return {
    type: `SET_${projectCode}_NEWS_DETAILS`,
    payload: p,
  };
};

export const setEventDetails = (p) => {
  return {
    type: `SET_${projectCode}_EVENT_DETAILS`,
    payload: p,
  };
};

export const setGeneralDetails = (p) => {
  return {
    type: `SET_${projectCode}_GENERAL_DETAILS`,
    payload: p,
  };
};

export const setHomepageContentDetails = (p) => {
  return {
    type: `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`,
    payload: p,
  };
};

export const setProgrammeDetails = (p) => {
  return {
    type: `SET_${projectCode}_PROGRAMME_DETAILS`,
    payload: p,
  };
};

export const setHomeSliderDetails = (p) => {
  return {
    type: `SET_${projectCode}_HOME_SLIDER_DETAILS`,
    payload: p,
  };
};

export const setSchoolsDetails = (p) => {
  return {
    type: `SET_${projectCode}_SCHOOLS_DETAILS`,
    payload: p,
  };
};

export const setGalleryDetails = (p) => {
  return {
    type: `SET_${projectCode}_GALLERY_DETAILS`,
    payload: p,
  };
};
